
import Basket from 'icons/basket-cart--desktop.svg?inline'
import RPHInput from 'rph/atoms/RPHInput/index.vue'
import transformedPrice from '@/filters/transformedPrice'

import MinusIcon from '~/assets/svg/minus.svg?inline'
import PlusIcon from '~/assets/svg/plus.svg?inline'
import PlusIconSmall from '~/assets/svg/plus-small.svg?inline'
import MinusIconSmall from '~/assets/svg/minus-small.svg?inline'

export default {
  name: 'ProductCounter',
  components: {
    RPHInput,
    MinusIcon,
    PlusIcon,
    PlusIconSmall,
    MinusIconSmall,
    Basket,
  },
  filters: { transformedPrice },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    inCart: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 1000,
    },
    available: {
      type: Number,
      default: 0,
    },
    withCounter: {
      type: Boolean,
      default: true,
    },
    withTopCounter: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    widthAdditionalCounter: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    isProductCard: {
      type: Boolean,
      default: false,
    },
    isCatalogCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amount: this.value,
      amountChange: null,
      emitAmountDebounced: null,
      isUserInput: false,
    }
  },
  computed: {
    productAmount() {
      return this.product.amount
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.amount = newVal
      },
    },
    amount: {
      immediate: true,
      handler() {
        if (this.amount > this.limit) {
          this.limitAmount()
        } else if (this.amount === '0') {
          this.amount = 1
        }
      },
    },
  },
  mounted() {
    this.amountChange = this.$debounce(() => this.manualAmountChange(), 600)
    this.emitAmountDebounced = this.$debounce(() => this.emitAmount(), 300)
  },
  methods: {
    async goToCart() {
      if (this.$route.path !== '/cart') {
        // @ts-ignore: Unreachable code error
        await this.$gtm.push({ event: 'click_on_cart' })
      }
       this.$router.push('/cart')
    },

    emitAmount() {
      this.$emit('input', this.amount)
    },

    decreaseAmount() {
      if ((this.amount > 1 && this.inCart) || this.amount > 0) {
        this.amount = +this.amount - 1
      }
    },
    increaseAmount() {
      if (this.amount < this.limit) {
        this.amount = +this.amount + 1
      }
    },
    manualAmountChange() {
      if (this.amount <= this.limit && this.amount >= 1 && this.isUserInput) {
        this.emitAmount()
      }
    },

    limitAmount() {
      this.amount = this.limit
      this.$emit('input', this.amount)
    },
  },
}
